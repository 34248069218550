import http from "../http-common";

class PersonaService {

    gerAllPersonas() {
        return http.get("/personas/persons");
    }

    getAllPersonasByEmpresa(data) {
        return http.post("/personas/persons/empresa", data);
    }

    getExcelAllPersonasByEmpresa(data) {
        return http.post("/personas/persons/xlsx", data ,{
            responseType: "arraybuffer"
        });
    }
}

export default new PersonaService();